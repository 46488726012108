<template>
  <div class="listPage">
    <div class="ziHead">
      <img
        v-if="indexImg && indexImg !== ''"
        :src="indexImg"
        style="width: 100%; height: 100%"
      />
      <img v-else :src="indexImg2" style="width: 100%; height: 100%" />
    </div>
    <div class="ziNav">
      <div class="navMain">
        <div class="navMainLeft">
          <span :class="{onActive : active  == item.entTChannelUuid}" v-for="(item, index) in slideList" :key="index"  class="spanActive">
            <!-- 无三级，有URL直接跳 -->
            <a class="navMainInner"
              v-if="!item.child.length && item.url && item.url !== ''&& item.entTChannelUuid !='01150003' " 
              target="_blank" 
              :href="item.url">
              {{item.name}}
            </a>
            <!-- 无三级，无URL跳本地 -->
            <a class="navMainInner"
              v-if="!item.child.length && (!item.url || item.url == '') || item.entTChannelUuid =='01150003'" 
              :href="item.catalog === 1 ? `/listPage/${item.entTChannelUuid}` 
              : item.catalog === 2 ? `/list/${item.entTChannelUuid}` 
              : `/listPic/${item.entTChannelUuid}`">
              {{item.name}}
            </a>
            <!-- 有三级，跳第一个三级url -->
            <a class="navMainInner"
              v-if="item.child.length && item.child[0].url && item.child[0].url !== '' "  
              :href="item.child[0].url">
              {{item.name}}
            </a>
            <!-- 有三级，三级无url，跳第一个三级本地 -->
            <a class="navMainInner"
              v-if="item.child.length && (!item.child[0].url || item.child[0].url == '')" 
              :href="item.child[0].catalog === 1 ? `/listPage/${item.child[0].entTChannelUuid}` 
              : item.child[0].catalog === 2 ? `/list/${item.child[0].entTChannelUuid}` 
              : `/listPic/${item.child[0].entTChannelUuid}`">
              {{item.name}}
            </a>
          </span>
        </div>
        <div class="navMainRight">
          <div style="float: right">
            <span>
              <router-link to="/">
                <i class="iconfont">&#xe689;</i> 
                <span> &nbsp; 首页 &nbsp;>&nbsp; </span>
              </router-link>
            </span>
            <span v-if="viewSlideHdParent" class="txt1"><a :href="slideHdParentUrl">{{slideHdParentName}}</a> </span> 
            <span v-if="slideHdName2!=''" class="txt2"> &nbsp;>&nbsp; {{slideHdName2}} </span>
            <span v-if="isthird" class="txt3">&nbsp; > {{slideHdName1}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="listPageContent">
      <div class="listContainer">
        <div class="detailCont" v-html="pageCont"></div>
      </div>
    </div>
  </div>
</template>
<script>
import {getChildChannel,getChannelInfo,getArtInfoByChannel,increaseViews} from '@/api/common'
export default {
  data() {
    return{
      isthird: false,
      mathedTitleUrl:'',
      slideHdName1: '',
      slideHdName2: '',
      slideHdTitle: '',
      viewSlideHdParent: true,
      slideHdParentName: '',
      slideHdParentUrl: '',
      slideHdName: '',
      active: 0,
      slideList: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      pageCont: '',
      indexImg: '',
      indexImg2: require("../assets/image/partyBuilding.jpg"),
    }
  },
  mounted() {
    var _this = this
    _this.active = _this.$route.params.id
    _this.listId = _this.$route.params.id
    _this.getChannelInfo()
    _this.getArtInfoByChannel()
  },
  methods: {
    // 查询二级栏目
    getChildChannel(id) {
      var _this = this
      let data = {
        applicationUuid: _this.webId,
        parentUuid: id
      }
      getChildChannel(data).then(res => {
        const {
          code,
          data
        } = res
        if (code === 1 && data) {
          _this.slideList = data
          if (_this.slideList.length) {
            _this.viewSlideHdParent = true
            let url = ''
            if (_this.slideList[0].catalog === 1) {
              url = `/listPage/${_this.slideList[0].entTChannelUuid}`
            } else if (_this.slideList[0].catalog === 2) {
              url = `/list/${_this.slideList[0].entTChannelUuid}`
            } else {
              url = `/list/${_this.slideList[0].entTChannelUuid}`
            }
            _this.slideHdParentUrl = url
          } else {
            _this.viewSlideHdParent = false
          }
        }
      })
    },
    getChannelInfo() {
      var _this = this
      let params1 = {
        channelUuid: _this.$route.params.id
      }
      getChannelInfo(params1).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          if( data.parentUuid== "0" ){
            // _this.slideHdTitle = data.name
            _this.slideHdParentName = data.name
            if(data.fileName && data.fileName !== ''){
              _this.indexImg = data.fileName
            }
            else{
              _this.indexImg = require('../assets/image/partyBuilding.jpg')
            }
            return
          } else {
            _this.getChildChannel(data.parentUuid)
          }
          _this.slideHdName1 = data.name

          let params2 = {
            channelUuid: data.parentUuid
          }
          getChannelInfo(params2).then(res => {
            const { code, data } = res
            if (code === 1 && data) {
              if (data.parentUuid == '0') {
                // _this.getChildChannel(data.entTChannelUuid)
                _this.slideHdParentName = data.name
                _this.slideHdName2 = _this.slideHdName1
                _this.slideHdTitle = _this.slideHdName2
                _this.isthird = false
                if(data.fileName && data.fileName !== ''){
                  _this.indexImg = data.fileName
                }
                else{
                  _this.indexImg = require('../assets/image/partyBuilding.jpg')
                }
              } else {
                _this.isthird = true
                // _this.getChildChannel(data.parentUuid)
                _this.slideHdName2 = data.name

                let params3 = {
                  channelUuid: data.parentUuid
                }
                getChannelInfo(params3).then(res => {
                  const { code, data } = res
                  if (code === 1 && data) {
                    _this.slideHdParentName = data.name
                    _this.slideHdTitle = _this.slideHdName1
                    // _this.indexImg = data.fileName
                    // _this.getChildChannel(data.parentUuid)
                    if(data.fileName && data.fileName !== ''){
                      _this.indexImg = data.fileName
                    }
                    else{
                      _this.indexImg = require('../assets/image/partyBuilding.jpg')
                    }
                  }
                })
              }
            }
          })
        }
      })
    },
    getArtInfoByChannel() {
      var _this = this
      let data = {
        entTApplicationUuid: _this.webId,
        channelUuid: _this.$route.params.id
      }
      getArtInfoByChannel(data).then(res => {
        const {
          code,
          data
        } = res
        if (code === 1 && data) {
          _this.pageCont = data.content
          this.increaseViews(data.entTArticleUuid)
        }
      })
    },
    increaseViews(id){
      let data = {
        entTArticleUuid: id
      }
      increaseViews(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          // _this.dataView = data
          // console.log(111,_this.dataView)
        }
      })
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'iconfont'; /* Project id 3165120 */
  src: url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff2?t=1644917438066') format('woff2'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff?t=1644917438066') format('woff'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.ttf?t=1644917438066') format('truetype');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 22px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.1px;
  -moz-osx-font-smoothing: grayscale;
}
.listPageContent{
  width: 1200px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0 1px 4px #333;
  padding: 30px 50px;
  background-color: #fff;
  line-height: 24px;
  box-sizing: border-box;
}
/* .listPage{
  margin-top: 72px;
} */
.listPage .ziHead {
  /* margin-top: 30px; */
  width: 100%;
  height: 417px;
  overflow: hidden;
  background-size: 100% 100%;
}
.ziNav {
    height: 42px;
    border-bottom: 1px solid #cacaca;
    margin-top: 15px;
    width: 100%!important;
}
.spanActive{
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  height: 42px;
  line-height: 43px;
  box-sizing: border-box;
  font-size: 17px;
}
.navMain {
    width: 1200px;
    height: auto;
    overflow: hidden;
    margin: 0 auto;
}
.listPage .navMainLeft {
    width: 880px;
    float: left;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
}
.navMainInner {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  height: 42px;
  line-height: 43px;
  box-sizing: border-box;
  font-size: 17px;
}
.navMainInner.active {
    border-bottom: 2px solid #0263b2;
}
.onActive a {
    border-bottom: 2px solid #0263b2;
}
.navMainRight span a{
    float: none;
    font-size: 13px;
    color: #a2a2a2;
    vertical-align: top;
}
.navMainRight {
    width: 320px;
    float: right;
    overflow: hidden;
    font-size: 13px;
    color: #a2a2a2;
    margin-top: 10px;
    line-height: 24px;
}
.navMainRight span {
  float: none;
  font-size: 13px;
  color: #a2a2a2;
  vertical-align: top;
}
.navMainRight .textTitle {
  float: none;
  font-size: 13px;
  color: #a2a2a2;
  vertical-align: top;
}
.listContainer {
    min-height: 500px;
    line-height: 30px;
    font-family: 宋体;
}
.detailHd {
    text-align: center;
    text-indent: 0px;
    font-family: 宋体;
    font-size: 18px;
    line-height: 28px;
    padding: 0px;
    margin: 5px 0px;
}
.detailCont {
    min-height: 300px;
    padding: 30px 0 40px 0;
    line-height: 32px;
    text-align: justify;
    font-size: 18px;
    line-height: 1.75em;
    text-indent: 2em;
}
@media (max-width: 1366px) {
  .listPageContent {
    width: 1000px;
  }
  .navMain{
    width: 1000px;
  }
   .listPage .navMainLeft {
    width: auto;
}
  .navMainRight {
    width: auto;
  }
  .detailCont video {
    max-width: 96% !important;
  }
  .detailCont img {
    max-width: 96% !important;
    height: auto !important;
  }
  .listPage{
    margin-top: 0px;
    width: auto;
  }
  
}
@media (max-width: 992px) {
  .listPageContent{
    width: 96%;
  }
  .listPage .ziHead {
    height: 362px;
  }
  .navMain {
    width: 96%;
  }
  .listPage .navMainLeft {
    width: auto;
}
  .navMainRight {
    width: auto;
  }
  .listPage{
    margin-top: 0px;
    width: auto;
  }
}
@media (max-width: 768px) {
  .listPage .ziHead {
    height: 200px;
  }
  .listPage{
    margin-top: 0px;
    width: auto;
  }
  .navMainRight{
    display: none;
  }
  .listPageContent{
    width: 94%;
    padding: 0 10px;
  }
  .detailCont img {
    max-width: 100% !important;
  }
   .navMain {
    width: 96%;
  }
  .listPage .navMainLeft {
    width: 100%;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
}
}
</style>